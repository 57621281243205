<template>
  <a-drawer
    width="1200px"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
    :destroyOnClose="true">
    <a-divider orientation="left">
      <b>选择升级包</b>
    </a-divider>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="升级包名称">
                <a-input v-model="queryParam.packageName" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <!-- <a-col :md="6" :sm="24">
              <a-form-item label="设备类型">
                <a-select v-model="queryParam.deviceTypeId" placeholder="请选择">
                  <a-select-option v-for="(item,key) in devList" :key="key" :value="item.id">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col> -->
            <a-col :md="6" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <!-- <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button> -->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="submitForm(record.id)">选中升级</a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </a-drawer>
</template>

<script>

import { deviceTypeList, deviceUpgradeAdd, deviceUpgradeAddBatch } from '@/api/pages/device'
import { pageappVersion } from '@/api/system/version'
import { tableMixin } from '@/store/table-mixin'
export default {
  mixins: [tableMixin],
  name: 'Version',
  data () {
    return {
      formTitle: '',
      typeList: [],
      open: false,
      list: [],
      loading: false,
      devList: [],
      total: 0,
      // 查询参数
      queryParam: {
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '升级包名称',
          dataIndex: 'packageName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '升级包版本',
          dataIndex: 'packageVersion',
          ellipsis: true,
          width: 100,
          align: 'center'
        },
        {
          title: '设备类型',
          dataIndex: 'deviceTypeName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '下载地址',
          dataIndex: 'packagePath',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',
          width: 160
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 150,
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      submitLoading: false,
      id: null,
      ids: null,
      type: null
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    getInfo(type, id, typeid) {
      console.log(type, id)
      this.type = type
      this.ids = id
      this.id = id
      this.queryParam.deviceTypeId = typeid
      this.open = true
      deviceTypeList().then(res => {
        this.devList = res.data
      })
      this.getList()
    },
    /** 查询升级列表 */
    getList () {
      this.loading = true
      pageappVersion(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
    },
    /** 选中 */
    submitForm (id) {
      this.loading = true
      if (this.type === 1) {
        deviceUpgradeAdd({ deviceId: this.id, upgradePackageId: id }).then(response => {
          this.$message.success(
            '绑定成功',
            3
          )
          this.open = false
          this.$emit('ok')
        }).finally(() => {
          this.loading = false
        })
      } else if (this.type === 2) {
        deviceUpgradeAddBatch({ deviceIds: this.ids, upgradePackageId: id }).then(response => {
          this.$message.success(
            '绑定成功',
            3
          )
          this.open = false
          this.$emit('ok')
        }).finally(() => {
          this.loading = false
        })
      }
    }
  }
}
</script>
