import request from '@/utils/request'

//         设备控制器

// 设备 分页
export function devicePage(query) {
  return request({
    url: '/up/up-device/page',
    method: 'get',
    params: query
  })
}

// 设备 详情
export function deviceInfo(query) {
  return request({
    url: '/up/up-device/detail',
    method: 'get',
    params: query
  })
}

// 设备 新增
export function deviceAdd(data) {
  return request({
    url: '/up/up-device/add',
    method: 'post',
    data
  })
}

// 设备 批量新增
export function deviceAddBatch(data) {
  return request({
    url: '/up/up-device/add/batch',
    method: 'post',
    data
  })
}

// 设备 编辑
export function deviceEdit(data) {
  return request({
    url: '/up/up-device/edit',
    method: 'post',
    data
  })
}

// 设备 删除
export function deviceDel(data) {
  return request({
    url: '/up/up-device/delete',
    method: 'post',
    data
  })
}

// 设备 复位
export function deviceReset(data) {
  return request({
    url: '/up/up-device/reset',
    method: 'post',
    data
  })
}

//              设备类型控制器

// 设备类型 列表
export function deviceTypeList(query) {
  return request({
    url: '/up/up-device-type/list',
    method: 'get',
    params: query
  })
}

// 设备类型 分页
export function deviceTypePage(query) {
  return request({
    url: '/up/up-device-type/page',
    method: 'get',
    params: query
  })
}

// 设备类型 详情
export function deviceTypeInfo(query) {
  return request({
    url: '/up/up-device-type/detail',
    method: 'get',
    params: query
  })
}

// 设备类型 新增
export function deviceTypeAdd(data) {
  return request({
    url: '/up/up-device-type/add',
    method: 'post',
    data
  })
}

// 设备类型 编辑
export function deviceTypeEdit(data) {
  return request({
    url: '/up/up-device-type/edit',
    method: 'post',
    data
  })
}

// 设备类型 删除
export function deviceTypeDel(data) {
  return request({
    url: '/up/up-device-type/delete',
    method: 'post',
    data
  })
}

//              设备阶梯类型控制器

// 异常记录 分页
export function deviceFaultPage(query) {
  return request({
    url: '/up/up-abnormal-record/page',
    method: 'get',
    params: query
  })
}

// 异常记录 删除
export function deviceFaultDelete(data) {
  return request({
    url: '/up/up-abnormal-record/delete',
    method: 'post',
    data
  })
}

// 异常记录 清空
export function deviceFaultClear(data) {
  return request({
    url: '/up/up-abnormal-record/clear',
    method: 'post',
    data
  })
}

// 新增设备升级
export function deviceUpgradeAdd(data) {
  return request({
    url: '/up/up-device-upgrade/add',
    method: 'post',
    data
  })
}

// 批量新增设备升级  验证类型
export function deviceUpgradeCheckBatch(data) {
  return request({
    url: '/up/up-device-upgrade/check/batch',
    method: 'post',
    data
  })
}

// 批量新增设备升级
export function deviceUpgradeAddBatch(data) {
  return request({
    url: '/up/up-device-upgrade/add/batch',
    method: 'post',
    data
  })
}
