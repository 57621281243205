import request from '@/utils/request'

// APP版本升级 分页
export function pageappVersion (query) {
  return request({
    url: '/up/up-upgrade-package/page',
    method: 'get',
    params: query
  })
}

// APP版本升级 详情
export function getappVersion (query) {
  return request({
    url: '/up/up-upgrade-package/detail',
    method: 'get',
    params: query
  })
}

// APP版本升级 新增
export function addappVersion (data) {
  return request({
    url: '/up/up-upgrade-package/add',
    method: 'post',
    data
  })
}

// APP版本升级 修改
export function editappVersion (data) {
  return request({
    url: '/up/up-upgrade-package/edit',
    method: 'post',
    data
  })
}

// APP版本升级 删除
export function delappVersion (data) {
  return request({
    url: '/up/up-upgrade-package/delete',
    method: 'post',
    data
  })
}
